<div class="container">
    <mat-card [class.can-hover]="canHover"
              [ngClass]="section1ColorClass"
              [class.text-contrast]="section1ColorClass"
              (click)="onClickCard(caption1)">
        <mat-icon>{{icon}}</mat-icon>

        <div class="card-content right-border"
             [ngClass]="flex_percent">
            <h1 class="items">{{items1}}</h1>
            <h3 class="caption">{{caption1}}</h3>
        </div>
    </mat-card>
    <mat-card [class.can-hover]="canHover"
              [ngClass]="section2ColorClass"
              [class.text-contrast]="section2ColorClass"
              (click)="onClickCard(caption2)">
        <div class="card-content right-border"
             [ngClass]="section2ColorClass"
             [ngClass]="flex_percent"
             *ngIf="caption2">
            <h1 class="items">{{items2}}</h1>
            <h3 class="caption">{{caption2}}</h3>
        </div>
    </mat-card>
    <mat-card *ngIf="caption3"
              [class.can-hover]="
              canHover"
              [ngClass]="section3ColorClass"
              [class.text-contrast]="section3ColorClass"
              (click)="onClickCard(caption3)">
        <div class="card-content"
             *ngIf="caption3"
             [ngClass]="section3ColorClass"
             [ngClass]="flex_percent">
            <h1 class="items">{{items3}}</h1>
            <h3 class="caption">{{caption3}}</h3>
        </div>
    </mat-card>
    <p *ngIf="subtext"
       class="subtext">
        {{subtext}}
    </p>
</div>
